"use strict";

let osanoScriptUrl = ''; // gets set within the switch below

var apiUrl = "";
var ENV = "";
var patientPortalUrl = "";

// get translations from AS incase needed without actually going through scheduler. eg when going straight to thankyou for booking at the end
let getSchedulerTextUrl = '/sitescheduler/textEdit/getTable';
let asApiUrl; //url specifically for appointment scheduler BE
let asBearerToken;


if (!String.prototype.includes) {
  String.prototype.includes = function () {
    'use strict';
    return String.prototype.indexOf.apply(this, arguments) !== -1;
  };
}

$(document).ready(function () {
  var url = window.location.href.split("?")[1];

  const searchParams = new URLSearchParams(window.location.search);
  if (url && (searchParams.has("phoneNumber") || searchParams.has("pn") || searchParams.has("campaign"))) {
    setCookie("campaign", "?" + url + "; secure");
  }

  //Next few lines are to remove the parameters from the URLs for some specific phone numbers
  sessionStorage.setItem("originalURL", window.location.href);
  let phoneNumber = searchParams.get("phoneNumber");

  let campaignId = searchParams.get("campaign"); // yes this will be supplied as campaign, but is always used as campaign_id beyond here
  if (campaignId) {
    phoneNumber = campaignId
  }

  /* GITPS-941
  let phoneNumbers = ["8001231234"];
  if (phoneNumbers.includes(phoneNumber)) {
    window.history.replaceState({}, document.title, "/");
  }
  */
});

function callGoogleAnalytics(method, object) {
  if (typeof ga === "function") {
    ga(method, object);
  }
}

async function setConfiguration(scripts = []) {
  let result;

  try {
    const configResponse = await fetch(
      `${window.location.origin}/config/getConfigurations`,
      {
        method: "GET",
        headers: {
          "Content-Language": "en-US",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      },
    );

    if (configResponse.ok) {
      result = await configResponse.json();
      switch (result.status) {
        case 200:
          // apiUrl = result.data.aesLocalDevelopmentApiUrl;
          apiUrl = result.data.aesApiUrl;
          osanoScriptUrl = result.data.osanoScriptUrl;
          ENV = result.data.env;
          // asApiUrl = result.data.asApiDevelpmentUrl;
          asApiUrl = result.data.asApiUrl;
          asBearerToken = result.data.asBearerToken;
          patientPortalUrl = result.data.patientPortalUrl;

          setGoogleAnalytics();

          const headElement = document.getElementsByTagName('head')[0];
          scripts.map(sc => {
            let script = document.createElement('script');
            script.src = sc;
            headElement.appendChild(script);
          })
          break;
      }
    }
  } catch (err) {
    console.error("Something went wrong: ", err);
  }
}

function setGoogleAnalytics() {
  if (ENV === "production") {
    //Google Analytics AC
    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");
    ga("create", "UA-63070800-3", "auto");
    ga("send", "pageview");
  } else {
    window.ga = function (type, dataObj, exception = false) {
      console.log(
        `%cDry run GA Event: %c${type} - ${JSON.stringify(dataObj)} ${exception ? JSON.stringify(exception) : ""}`,
        `color: #E27400`,
        `color: #F9AB00`
      );
      if (dataObj.hitCallback) {
        (() => dataObj.hitCallback())(); //iife to run hitCallback function
      }
    };
  }
}
